import { render, staticRenderFns } from "./form-error-list.vue?vue&type=template&id=1a44540a&"
import script from "./form-error-list.vue?vue&type=script&lang=ts&"
export * from "./form-error-list.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/scalefish/releases/72/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a44540a')) {
      api.createRecord('1a44540a', component.options)
    } else {
      api.reload('1a44540a', component.options)
    }
    module.hot.accept("./form-error-list.vue?vue&type=template&id=1a44540a&", function () {
      api.rerender('1a44540a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/form-error-list.vue"
export default component.exports